import { Controller } from "stimulus"
import interact from 'interactjs'

export default class extends Controller {

  static targets = [
    "elementFields",
    "draggableElement",
    "submitBtn",
    "statusIndicator",
    "form"
  ]

  initialize() {
    // console.log('editing zones init');
    
    window.copiedStyle = {
      styleCopied: false,
      fontFamily: null,
      fontSize: null,
      colour: null,
      left: null,
      top: null,
      size: null,
      textAlign: null,
      lineHeight: null
    };

    // disconnect draggable whole zones 
    const zones = document.getElementsByClassName('dynamic-pricing-zone');
    for (var i = 0; i < zones.length; i++) {
      interact(`.${zones[i].id}`).unset()
    }

    this.draggableElementTargets.forEach(element => {
      if(element.dataset.element == 'image'){
        this.imageElement(element)
      } else {
        this.defaultElement(element)
      }
    });
  }

  // START DRAGGABLE ELEMENTS
  defaultElement(element) {
    const position = { x: 0, y: 0 }
    const controller = this;


    interact(`#${element.id}`).draggable({
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: 'parent'
        }),
        interact.modifiers.snap({
          targets: [
            interact.snappers.grid({ x: 5, y: 5 })
          ],
          range: Infinity,
          relativePoints: [ { x: 0, y: 0 } ]
        })
      ],
      listeners: {
        move (event) {
          // keep the dragged position in the data-x/data-y attributes
          position.x = (parseFloat(event.target.getAttribute('data-x')) || 0) + event.dx,
          position.y = (parseFloat(event.target.getAttribute('data-y')) || 0) + event.dy;

          event.target.style.transform =
            `translate(${position.x}px, ${position.y}px)`

          controller.updatePositionAttributes(event.target, element, position.x, position.y)
        },
      }
    }).resizable({
      margin: 5,
      edges: { top: false, left: true, bottom: false, right: true },
      listeners: {
        move: function (event) {
          let { x, y } = event.target.dataset

          x = (parseFloat(x) || 0) + event.deltaRect.left
          y = (parseFloat(y) || 0) + event.deltaRect.top

          Object.assign(event.target.style, {
            width: `${event.rect.width}px`,
            height: `${event.rect.height}px`,
            transform: `translate(${x}px, ${y}px)`
          })

          Object.assign(event.target.dataset, { x, y })

          controller.updateSizeAttributes(event.target, element)
          controller.updatePositionAttributes(event.target, element, x, y)
        }
      }
    })
  }

  imageElement(element) {
    const position = { x: 0, y: 0 }
    const controller = this;

    interact(`#${element.id}`).draggable({
      modifiers: [
        interact.modifiers.snap({
          targets: [
            interact.snappers.grid({ x: 5, y: 5 })
          ],
          range: Infinity,
          relativePoints: [ { x: 0, y: 0 } ]
        })
      ],
      listeners: {
        move (event) {
          // keep the dragged position in the data-x/data-y attributes
          position.x = (parseFloat(event.target.getAttribute('data-x')) || 0) + event.dx,
          position.y = (parseFloat(event.target.getAttribute('data-y')) || 0) + event.dy;

          event.target.style.transform =
            `translate(${position.x}px, ${position.y}px)`

          controller.updatePositionAttributes(event.target, element, position.x, position.y)
        },
      }
    }).resizable({
      modifiers: [
        interact.modifiers.aspectRatio({
          ratio: 'preserve',
        })
      ],
      margin: 5,
      edges: { top: true, left: true, bottom: true, right: true },
      listeners: {
        move: function (event) {
          let { x, y } = event.target.dataset

          x = (parseFloat(x) || 0) + event.deltaRect.left
          y = (parseFloat(y) || 0) + event.deltaRect.top

          Object.assign(event.target.style, {
            width: `${event.rect.width}px`,
            height: `${event.rect.height}px`,
            transform: `translate(${x}px, ${y}px)`
          })

          Object.assign(event.target.dataset, { x, y })

          controller.updateSizeAttributes(event.target, element)
        }
      }
    })
  }

  updateSizeAttributes(target, element) {
    var relativeWidth = (target.offsetWidth / target.parentElement.offsetWidth * 100).toFixed(2)

    const elementFields = document.getElementById(`fields-${element.id}`)
    var sizeInput = elementFields.querySelector('.size-input');
    sizeInput.value = relativeWidth;
    this.enableSubmit();
  }

  updatePositionAttributes(target, element, positionX, positionY) {
    const relativePosition = { left: 0, top: 0 }

    relativePosition.left = (parseFloat(target.style.left) + (positionX / target.parentElement.offsetWidth * 100)).toFixed(2)
    relativePosition.top = (parseFloat(target.style.top) + (positionY / target.parentElement.offsetHeight * 100)).toFixed(2)

    if(element.dataset.element != 'image'){
      if(relativePosition.left < 0) {
        relativePosition.left = 0
      }
      if(relativePosition.left > 100) {
        relativePosition.left = 100
      }
      if(relativePosition.top < 0) {
        relativePosition.top = 0
      }
      if(relativePosition.top > 100) {
        relativePosition.top = 100
      }
    }

    target.dataset.x = positionX;
    target.dataset.y = positionY;

    const elementFields = document.getElementById(`fields-${element.id}`)
    var leftInput = elementFields.querySelector('.left-input');
    var topInput = elementFields.querySelector('.top-input');
    leftInput.value = relativePosition.left
    topInput.value = relativePosition.top

    this.enableSubmit();
  }
  // END DRAGGABLE ELEMENTS


  // START TOGGLE FORM FIELDS
  toggleElementFields(){
    this.hideElementFields();

    let elementId = event.currentTarget.id;
    let fieldsForm = document.getElementById(`fields-${elementId}`);
    if (fieldsForm){
      fieldsForm.classList.remove('hidden');
    }

    document.querySelectorAll('.zone-element').forEach(function(element) {
      element.classList.remove('ring');
    });
    event.currentTarget.classList.add('ring');

    let zoneId = event.currentTarget.dataset.zoneId;
    const thumbBtn = document.getElementById(`zone-thumb-btn-${zoneId}`);
    if (thumbBtn){thumbBtn.classList.add('ring', 'bg-blue-100');}

    if (event.currentTarget.id.includes('zone-thumb-btn')){
      // if clicked in zone thumb focus first element in zone and un hide form fields
      const firstElementId = thumbBtn.dataset.firstZoneElementId;
      fieldsForm = document.getElementById(`fields-editing-zone-element-${firstElementId}`);
      if (fieldsForm){ fieldsForm.classList.remove('hidden'); }
      const element = document.getElementById(`editing-zone-element-${firstElementId}`);
      if (element){ element.classList.add('ring'); }
    }
  }

  hideElementFields(){
    this.elementFieldsTargets.forEach(target => {
      target.classList.add('hidden');
    });

    document.querySelectorAll('.zone-thumb-btn').forEach(target => {
      target.classList.remove('ring', 'bg-blue-100');
    });
  }
  // END TOGGLE FORM FIELDS


  // START COPY - PASTE STYLE
  copyStyle(){
    event.preventDefault();
    const elementFields = event.currentTarget.closest(".element-fields");

    const leftInput = elementFields.querySelector('.left-input');
    const topInput = elementFields.querySelector('.top-input');
    const sizeInput = elementFields.querySelector('.size-input');

    const fontFamilyInput = elementFields.querySelector('.font-family-input');
    const fontSizeInput = elementFields.querySelector('.font-size-input');
    const colourInput = elementFields.querySelector('.ghost-colour-input');
    const textAlignInput = elementFields.querySelector('.text-align-input');
    const lineHeightInput = elementFields.querySelector('.line-height-input');

    window.copiedStyle.styleCopied = true;
    window.copiedStyle.left = leftInput.value;
    window.copiedStyle.top = topInput.value;
    window.copiedStyle.size = sizeInput.value;

    if(fontSizeInput){
      window.copiedStyle.fontFamily = fontFamilyInput.value;
      window.copiedStyle.fontSize = fontSizeInput.value;
      window.copiedStyle.colour = colourInput.value;
      window.copiedStyle.textAlign = textAlignInput.value;
      window.copiedStyle.lineHeight = lineHeightInput.value || lineHeightInput.dataset.default;
    }

    document.querySelectorAll('.paste-style-btn').forEach(function(button) {
      button.disabled = false;
    });

    const tooltip = event.currentTarget.nextElementSibling;
    var tooltipText = tooltip.querySelector('.tooltip-inner');
    tooltipText.innerHTML = event.currentTarget.dataset.titleTwo;

    // console.log(window.copiedStyle);
  }

  pasteStyle(){
    event.preventDefault();
    // console.log(window.copiedStyle);
    const elementFields = event.currentTarget.closest(".element-fields");

    const leftInput = elementFields.querySelector('.left-input');
    const topInput = elementFields.querySelector('.top-input');
    const sizeInput = elementFields.querySelector('.size-input');

    const fontFamilyInput = elementFields.querySelector('.font-family-input');
    const fontSizeInput = elementFields.querySelector('.font-size-input');
    const colourInput = elementFields.querySelector('.ghost-colour-input');
    const textAlignInput = elementFields.querySelector('.text-align-input');
    const lineHeightInput = elementFields.querySelector('.line-height-input');

    if(window.copiedStyle.left && leftInput){ leftInput.value = window.copiedStyle.left}
    if(window.copiedStyle.top && topInput){ topInput.value = window.copiedStyle.top}
    if(window.copiedStyle.size && sizeInput){ sizeInput.value = window.copiedStyle.size}

    if(window.copiedStyle.fontFamily && fontFamilyInput){ fontFamilyInput.value = window.copiedStyle.fontFamily}
    if(window.copiedStyle.fontSize && fontSizeInput){ fontSizeInput.value = window.copiedStyle.fontSize}
    if(window.copiedStyle.colour && colourInput){ colourInput.value = window.copiedStyle.colour}
    if(window.copiedStyle.textAlign && textAlignInput){ textAlignInput.value = window.copiedStyle.textAlign}
    if(window.copiedStyle.lineHeight && lineHeightInput){ lineHeightInput.value = window.copiedStyle.lineHeight}

    const tooltip = event.currentTarget.nextElementSibling;
    var tooltipText = tooltip.querySelector('.tooltip-inner');
    tooltipText.innerHTML = event.currentTarget.dataset.titleTwo;

    this.fullUpdateElement();
  }
  // END COPY - PASTE STYLE

 // START ADD/DELETE ZONE ELEMENTS
  addNewZoneElement(e){
    e.preventDefault();
    const form = document.getElementById('elements-editing-form');

    let newElementInput = document.getElementById('new-element-input');
    newElementInput.value = e.currentTarget.dataset.type;

    let newElementZoneId = document.getElementById('new-element-zone-id-input');
    newElementZoneId.value = e.currentTarget.dataset.zoneId;

    this.disablePageLinks();
    form.submit(); // full submit to reinit controller with new elements
  }

  deleteZoneElement(e){
    e.preventDefault();

    const form = document.getElementById('elements-editing-form');
    let deleteElemenId = document.getElementById('delete-element-id-input');
    let newElementZoneId = document.getElementById('new-element-zone-id-input');
    
    if (window.confirm(e.currentTarget.dataset.confirmationMsg)) {
      newElementZoneId.value = e.currentTarget.dataset.zoneId;
      deleteElemenId.value = e.currentTarget.dataset.elementId;

      this.disablePageLinks();
      form.submit(); // full submit to reinit controller with new elements
    }
  }

  disablePageLinks(){
    const links = document.links;

    for (var i = 0; i < links.length; i++) {
      links[i].onclick = function() {return false;};
      // links[i].setAttribute('disabled', 'disabled');
      // links[i].setAttribute('data-action', '');
    }
  }
  // END ADD/DELETE ZONE ELEMENTS

  // UPDATE ELEMENTS
  updateFontSizeElement() {
    const elementFields = event.currentTarget.closest(".element-fields");
    const elementId = `editing-${elementFields.dataset.formElementId}`;
    const element = document.getElementById(elementId);

    const fontSizeInput = elementFields.querySelector('.font-size-input');
    element.dataset['dynamicPricing-ModerateScaleSizeValue'] = fontSizeInput.value

    const trigger = new CustomEvent("textSizeChanged");
    window.dispatchEvent(trigger);
    this.enableSubmit();
  }

  updateLineHeightElement() {
    const elementFields = event.currentTarget.closest(".element-fields");
    const elementId = `editing-${elementFields.dataset.formElementId}`;
    const element = document.getElementById(elementId);
    const lineHeightInput = elementFields.querySelector('.line-height-input');

    if (lineHeightInput.value !== null && lineHeightInput.value !== ''){
      element.style.lineHeight = lineHeightInput.value;
      this.enableSubmit();
    }
  }

  fullUpdateElement(){
    const elementFields = event.currentTarget.closest(".element-fields");
    const elementId = `editing-${elementFields.dataset.formElementId}`;
    const element = document.getElementById(elementId);

    const leftInput = elementFields.querySelector('.left-input');
    const topInput = elementFields.querySelector('.top-input');
    const sizeInput = elementFields.querySelector('.size-input');

    const fontFamilyInput = elementFields.querySelector('.font-family-input');
    const ghostColourInput = elementFields.querySelector('.ghost-colour-input');
    const colourInput = elementFields.querySelector('.colour-input');
    const textAlignInput = elementFields.querySelector('.text-align-input');

    element.style.width = sizeInput.value + '%';
    element.style.left = leftInput.value + '%';
    element.style.top = topInput.value + '%';

    if (ghostColourInput){
      element.style.color = ghostColourInput.value; 
      element.style.borderColor = ghostColourInput.value;
      colourInput.value = ghostColourInput.value;
    }

    if (fontFamilyInput) {
      element.style.fontFamily = fontFamilyInput.value;
      element.style.textAlign = textAlignInput.value;
      this.updateFontSizeElement()
      this.updateLineHeightElement()
    }

    this.enableSubmit();
  }

  checkInputValue(value) {
    return (value <= 100 && value >= -100)
  }

  enableSubmit(){
    this.submitBtnTarget.disabled = false;

    const statusIdicator = this.statusIndicatorTarget;
    const bubble = statusIdicator.querySelector('#status-bubble');
    
    statusIdicator.classList.remove('opacity-60');
    bubble.classList.remove('bg-gray-500')
    bubble.classList.add('bg-yellow-300');
  }

  submitForm(event){
    event.preventDefault();
    this.disablePageLinks();
    this.formTarget.requestSubmit();
  }
}
