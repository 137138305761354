import { Controller } from "stimulus"
import interact from 'interactjs'

export default class extends Controller {

  static targets = [
    "draggableZone",
    "submitBtn",
    "statusIndicator",
    "form"
  ]

  initialize() {
    console.log('draggable zones init');

    this.draggableZoneTargets.forEach(element => {
      this.draggableZone(element)
    });
  }


  draggableZone(element) {
    const position = { x: 0, y: 0 }
    const controller = this;

    interact(`#${element.id}`).draggable({
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: 'parent'
        }),
        interact.modifiers.snap({
          targets: [
            interact.snappers.grid({ x: 5, y: 5 })
          ],
          range: Infinity,
          relativePoints: [ { x: 0, y: 0 } ]
        })
      ],
      listeners: {
        move (event) {
          // keep the dragged position in the data-x/data-y attributes
          position.x = (parseFloat(event.target.getAttribute('data-x')) || 0) + event.dx,
          position.y = (parseFloat(event.target.getAttribute('data-y')) || 0) + event.dy;

          event.target.style.transform = `translate(${position.x}px, ${position.y}px)`;

          controller.updatePositionAttributes(event.target, element, position.x, position.y)
        },
      }
    }).resizable({
      margin: 5,
      edges: { top: true, left: true, bottom: true, right: true },
      listeners: {
        move: function (event) {
          let { x, y } = event.target.dataset

          x = (parseFloat(x) || 0) + event.deltaRect.left
          y = (parseFloat(y) || 0) + event.deltaRect.top

          Object.assign(event.target.style, {
            width: `${event.rect.width}px`,
            height: `${event.rect.height}px`,
            transform: `translate(${x}px, ${y}px)`
          })

          Object.assign(event.target.dataset, { x, y })

          controller.updateSizeAttributes(event.target, element)
          controller.updatePositionAttributes(event.target, element, x, y)
        }
      }
    })
  }

  updateSizeAttributes(target, element) {
    var relativeWidth = (target.offsetWidth / target.parentElement.offsetWidth * 100).toFixed(2)
    var relativeHeight = (target.offsetHeight / target.parentElement.offsetHeight * 100).toFixed(2)

    const zoneFields = document.getElementById(`fields-zone-${element.dataset.zoneId}`);
    var widthInput = zoneFields.querySelector('.width-input');
    var heightInput = zoneFields.querySelector('.height-input');

    widthInput.value = relativeWidth
    heightInput.value = relativeHeight

    this.enableSubmit();
  }

  updatePositionAttributes(target, element, positionX, positionY) {
    const relativePosition = { left: 0, top: 0 }

    relativePosition.left = (parseFloat(target.style.left) + (positionX / target.parentElement.offsetWidth * 100)).toFixed(2)
    relativePosition.top = (parseFloat(target.style.top) + (positionY / target.parentElement.offsetHeight * 100)).toFixed(2)

    target.dataset.x = positionX
    target.dataset.y = positionY

    const zoneFields = document.getElementById(`fields-zone-${element.dataset.zoneId}`);
    var leftInput = zoneFields.querySelector('.left-input');
    var topInput = zoneFields.querySelector('.top-input');

    leftInput.value = relativePosition.left
    topInput.value = relativePosition.top

    this.enableSubmit();
  }

  updateZone() {
    if (this.checkInputValue(event.currentTarget.value)) {
      const elementFields = event.currentTarget.closest(".zone-editing-fields");
      const elementId = elementFields.dataset.targetId;
      const element = document.getElementById(elementId);

      const leftInput = elementFields.querySelector('.left-input');
      const topInput = elementFields.querySelector('.top-input');
      const widthInput = elementFields.querySelector('.width-input');
      const heightInput = elementFields.querySelector('.height-input');
      
      element.style.left = leftInput.value + '%';
      element.style.top = topInput.value + '%';
      element.style.width = widthInput.value + '%';
      element.style.height = heightInput.value + '%';
    } else {
      event.currentTarget.value = event.currentTarget.defaultValue;
    }
    this.enableSubmit();
  }

  toggleZoneFields(){
    this.hideZoneFields();
    let zoneId = event.currentTarget.dataset.zoneId;

    this.draggableZoneTargets.forEach(element => {
      element.classList.remove('ring');
      if (element.dataset.zoneId === zoneId ){ element.classList.add('ring'); }
    });

    const fieldsForm = document.getElementById(`fields-zone-${zoneId}`);
    if (fieldsForm){ fieldsForm.classList.remove('hidden'); }

    const thumbBtn = document.getElementById(`zone-thumb-btn-${zoneId}`);
    if (thumbBtn){ thumbBtn.classList.add('ring', 'bg-blue-100'); }
  }

  hideZoneFields(){
    document.querySelectorAll('.zone-fields').forEach(target => {
      target.classList.add('hidden');
    });

    document.querySelectorAll('.zone-thumb-btn').forEach(target => {
      target.classList.remove('ring', 'bg-blue-100');
    });
  }

  disablePageLinks(){
    console.log('disablePageLinks')
    const links = document.links;

    for (var i = 0; i < links.length; i++) {
      links[i].onclick = function() {return false;};
      links[i].setAttribute('disabled', 'disabled');
      links[i].setAttribute('data-action', '');
    }
  }

  enableSubmit(){
    this.submitBtnTarget.disabled = false;

    const statusIdicator = this.statusIndicatorTarget;
    const bubble = statusIdicator.querySelector('#status-bubble');
    
    statusIdicator.classList.remove('opacity-60');
    bubble.classList.remove('bg-gray-500')
    bubble.classList.add('bg-yellow-300');
  }

  submitForm(event){
    console.log('submitting form')
    event.preventDefault();
    this.disablePageLinks();
    this.formTarget.requestSubmit();
  }

  checkInputValue(value) {
    return (value <= 100 && value >= -100)
  }
}